export const baseURL = process.env.REACT_APP_BASEURL;
export const timeFormat = 'hh:mm A';
export const dateFormat = 'DD MMM YYYY';
export const dateTimeFormat = 'DD MMM YYYY, hh:mm A';
export const limitId = 10;


// Export Excel File Headers
export const excelSheetheaders = [
    { label: "Order Id", key: "id" },
    { label: "Store Name", key: "store_name" },
    { label: "Created By", key: "created_by" },
    { label: "Customer Name", key: "billing.first_name" },
    { label: "Total Amount", key: "total" },
    { label: "Tax", key: "total_tax" },
    { label: "Order Status", key: "status" },
    { label: "Tip Amount", key: "tip" },
    { label: "Created Date", key: "date_created" },
];
