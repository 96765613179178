import { dateFormat, dateTimeFormat, timeFormat, limitId } from "../utils/constants";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const useUtilsFunction = () => {

    const showTimeFormat = (data) => {
        return moment(data).format(timeFormat);
    };

    const showDateFormat = (data) => {
        return moment(data).format(dateFormat);
    };

    const showDateTimeFormat = (data) => {
        return moment(data).format(dateTimeFormat);
    };

    const getNumber = (value = 0) => {
        return Number(parseFloat(value || 0).toFixed(2));
    };

    const showingImage = (data) => {
        return data !== undefined && data;
    };

    const showingUrl = (data) => {
        return data !== undefined ? data : "!#";
    };

    const catchError = (error) => {
        const errorMessage = error.response?.data?.messageEn || error.response?.data?.message || error.message
        return errorMessage
    }

    const truncateString = (str) => {
        if (str?.length <= limitId) {
            return str;
        } else {
            return str?.slice(0, limitId) + '...';
        }
    }

    const trimFileName = (file, maxLength = 16) => {
        if (file?.length > 14) {
            const fileName = file instanceof File ? file.name : file;
            if (fileName.length > maxLength) {
                return (
                    fileName.slice(0, 4) +
                    "..." +
                    fileName.slice(fileName.length - maxLength)
                );
            } else {
                return fileName;
            }
        } else {
            return file;
        }
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((value, key) => {
            return value && value[key] !== 'undefined' ? value[key] : null;
        }, obj);
    };

    const exportToExcel = (data, fileName) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });
        const blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        
        saveAs(blob, fileName);
    };

    // const exportToExcel = (data, fileName) => {
    //     const worksheet = XLSX.utils.json_to_sheet(data);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    //     // Buffer to store the generated Excel file
    //     const excelBuffer = XLSX.write(workbook, {
    //         bookType: "xlsx",
    //         type: "array",
    //     });
    //     const blob = new Blob([excelBuffer], {
    //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    //     });
    
    //     // Ensure compatibility with iOS by checking if saveAs is supported
    //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //         // For IE and Edge
    //         window.navigator.msSaveOrOpenBlob(blob, fileName);
    //     } else {
    //         const url = window.URL.createObjectURL(blob);
    
    //         // For iOS Safari or Chrome on iOS
    //         const anchor = document.createElement('a');
    //         anchor.href = url;
    //         anchor.style = "display: none"; // Hidden link
    //         anchor.download = fileName;
    //         document.body.appendChild(anchor);
    //         anchor.click();
    
    //         // Cleanup
    //         document.body.removeChild(anchor);
    //         window.URL.revokeObjectURL(url);
    //     }
    // };


// const exportToExcel = (data, fileName) => {
//     const worksheet = XLSX.utils.json_to_sheet(data);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

//     // Buffer to store the generated Excel file
//     const excelBuffer = XLSX.write(workbook, {
//         bookType: "xlsx",
//         type: "array",
//     });
//     const blob = new Blob([excelBuffer], {
//         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
//     });

//     // Fallback mechanism for iOS devices
//     if (navigator.userAgent.match(/iPad|iPhone|iPod/) && window.navigator && window.navigator.msSaveOrOpenBlob) {
//         // For iOS devices on Chrome/Safari, ensure user interaction and try window.open as a fallback
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.href = url;
//         a.download = fileName;

//         // Directly append to body to avoid sandboxing issues
//         document.body.appendChild(a);
//         a.click();

//         // Cleanup
//         document.body.removeChild(a);
//         window.URL.revokeObjectURL(url);
//     } else {
//         // Normal saveAs function for non-iOS devices
//         saveAs(blob, fileName);
//     }
// };


    return {
        getNumber,
        showTimeFormat,
        showDateFormat,
        showingImage,
        showingUrl,
        showDateTimeFormat,
        catchError,
        truncateString,
        trimFileName,
        exportToExcel,
        getNestedValue
    };
};

export default useUtilsFunction;
