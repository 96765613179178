import requests from "./httpServices";

const CustomerServices = {

  addCustomerApi: async (body) => {
    return requests.post("/wc/v3/customers", body);
  },

  getCustomerListApi: async (params) => {
    return requests.get("/wc/v3/customers", params);
  },

};

export default CustomerServices;
