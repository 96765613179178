import requests from "./httpServices";

const AuthServices = {
    loginAdmin: async (payload) => {
      return  requests.post(`/jwt-auth/v1/token`, payload);
    },

    getAdminDetail: async (payload) => {
      return  requests.post(`/wp/v2/users/me`, payload);
    },

    getShopUsers: async (params) => {
      return  requests.get(`/wp/v2/users`, params);
    },
};

export default AuthServices;
