import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
    const location = useLocation();
    const pathName = location.pathname
    return (
        <div >
            {pathName === '/' &&
                <Sidebar />
            }
            <Header />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
