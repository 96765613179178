import requests from "./httpServices";

const OrderServices = {

  createOrderApi: async (body) => {
    return requests.post("/wc/v3/orders", body);
  },

  removeOrderApi: async (id) => {
    return requests.delete(`/wc/v3/orders/${id}`, { "force": true });
  },

  getAllOrdersApi: async (params) => {
    return requests.get("/wc/v3/orders", params);
  },

  uploadSignature: async (body) => {
    return requests.post("/custom-api/v1/capture_customer_signature", body);
  },

};

export default OrderServices;
